import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

export default makeStyles<Theme>((theme) =>
  createStyles({
    benefitsWrapper: {
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
      display: 'flex',
      height: '49px',
      justifyContent: 'space-evenly',
      marginTop: '12px',
      width: '100%',
    },
    bubble: {
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        background: ColorsPalette.neutral2,
        border: '1px solid ' + ColorsPalette.neutral1,
        borderRadius: '4px',
        margin: '0 16px',
        overflow: 'hidden',
        padding: '2px 10px',
        position: 'relative',
        width: 'calc(100% - 32px)',
      },
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
    }
  })
);
