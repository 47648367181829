import Slide from '@mui/material/Slide';
import React, { ReactElement } from 'react';

import Hidden from '@components/common/Hidden';
import BenefitStyles from './Benefit.styles';

export interface IBenefit {
  active: boolean;
  icon: JSX.Element;
  description: string | JSX.Element;
  key: string;
}

interface Props {
  active: boolean;
  benefit: IBenefit;
}

interface ItemProps {
  benefit: IBenefit;
  refProp?: any;
}

interface MobileProps {
  benefit: IBenefit;
  Component: any;
  isActive: boolean;
}

interface DesktopProps {
  children: ReactElement<any, any> | undefined;
}

function BenefitMobileWrapper({ isActive, Component, benefit }: MobileProps) {
  const RefComponent = React.forwardRef((props, ref) => <Component benefit={benefit} refProp={ref} />);
  RefComponent.displayName = 'RefComponent';
  return (
    <Hidden mdUp={true}>
      <Slide
        direction={isActive ? 'right' : 'left'}
        in={isActive}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 1000,
          exit: 550,
        }}
      >
        <RefComponent />
      </Slide>
    </Hidden>
  );
}

function BenefitDesktopWrapper({ children }: DesktopProps) {
  return (
    <Hidden mdDown={true}>
      {children}
    </Hidden>
  );
}

function BenefitItem({ benefit, refProp }: ItemProps) {
  const classes = BenefitStyles();
  return (
    <div className={classes.benefitContainer} ref={refProp}>
      <div className="icon">{benefit.icon}</div>
      <div className={classes.benefitContainerDescription}>{benefit.description}</div>
    </div>
  );
}

export default function Benefit({active, benefit }: Props) {
  return (
    <>
      <BenefitMobileWrapper
        isActive={active}
        Component={BenefitItem}
        benefit={benefit}
      />
      <BenefitDesktopWrapper>
        <BenefitItem benefit={benefit} />
      </BenefitDesktopWrapper>
    </>
  );
}
