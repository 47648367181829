import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    benefitContainer: {
      ...{
        '& .icon': {
          marginRight: 15,
        },
        color: ColorsPalette.primary,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          width: 'calc(100% - 20px)',
        },
      },
      ...TypographyCollection.HLinkBoldS,
    },
    benefitContainerDescription: {
      display: 'flex',
    },
  }),
);
