import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useTranslation } from '@hooks/useTranslation';
import { IHomePageComponentDataObject } from '@interfaces/home';
import CheckIconSvg from '../../../../public/static/frontpage/check.svg';
import ConversationSmileIconSvg from '../../../../public/static/frontpage/conversation-smile.svg';
import HeartIconSvg from '../../../../public/static/frontpage/heart.svg';
import Benefit from './Benefit';

import BenefitsWrapperStyles from './BenefitsWrapper.styles';

const BenefitsWrapper: React.FunctionComponent<{ component: IHomePageComponentDataObject; className?: string; }> = ({ component, className }) => {
  const classes = BenefitsWrapperStyles();
  const { t } = useTranslation();
  const benefitsArray = [
    {
      active: true,
      description: t('common.free_delivery'),
      icon: <CheckIconSvg />,
      key: 'fast',
    },
    {
      active: false,
      description: t('frontpage.leading_brands'),
      icon: <HeartIconSvg />,
      key: 'amazing',
    },
    {
      active: false,
      description: t('frontpage.customer_support'),
      icon: <ConversationSmileIconSvg />,
      key: 'superb',
    },
  ];

  const [activeKey, setActiveKey] = React.useState<number>(0);

  const getNextBenefitKey = (): number => {
    const count = Object.keys(benefitsArray).length;
    const activeBenefitIndex = activeKey || 0;
    return count > activeBenefitIndex + 1 ? activeBenefitIndex + 1 : 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setActiveKey(getNextBenefitKey());
    }, 5000);
  }, [activeKey]);

  return (
    <div className={classNames(classes.benefitsWrapper, className)}>
      <div className={classes.bubble}>
        {benefitsArray.map((benefit, key) => (
          <Benefit
            active={key === activeKey}
            benefit={benefit}
            key={benefit.key}
          />
        ))}
      </div>
    </div>
  );
};

export default BenefitsWrapper;
